$font-family-sans-serif: 'Arial';
$h1-font-size: 3.5rem;

$theme-colors: (
  "primary": #37c48a,
  "secondary": #4615d1,
  "danger": #ff4136,
  "neutral": #666,
  "neutralLight": #f0f0f0,
);

$input-btn-font-size: 1.4rem;
$border-radius: 2px;

//NAVBAR
$navbar-padding-y: 2rem;
$navbar-nav-link-padding-x: 0.6rem;

$carousel-control-color: #666;

.background--gradient-primary {
  background: linear-gradient(120deg, #41cc93, #1becb5);
}
