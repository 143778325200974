
.navbar {
  transition: all 0.8s;
  will-change: transform;

  @include media-breakpoint-down(lg) {
    padding: 1.4rem 0.6rem;
  }

  &-nav {
    .nav-link.nav-link {
      text-transform: uppercase;
      font-size: 1rem;
      position: relative;
      margin-left: 1rem;
      margin-right: 1rem;
      color: rgba(255, 255, 255, 0.85);

      &:hover, &.active {
        color: rgba(255, 255, 255, 1);
      }

      &:after {
        background: rgba(255, 255, 255, 1);
        transition: all .5s;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 0;
        content: '.';
        color: transparent;
        height: 1px;
      }

      &:hover:after, &.active:after {
        width: 100%;
      }
    }
  }

  &-brand {
    position: relative;

    img {
      width: 160px;
      height: auto;
      transition: all 0.8s;
    }

    &--primary {

    }

    &--secondary {
      position: absolute;
      left: 0;
      opacity: 0;
    }
  }

  &.scrolled {
    padding: 0.8rem 1rem;
    background: rgba(55, 55, 55, 0.8);

    .navbar-brand {
      img {
        width: 80px;
      }

      &--primary {
        opacity: 0;
      }

      &--secondary {
        opacity: 1;
      }
    }
  }

  .hamburger {
    z-index: 2;
    right: 0;

    &:focus {
      outline: none;
    }
  }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: #fff;
  }

  .hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
    background-color: map-get($theme-colors, 'primary');
  }

  @include media-breakpoint-down(md) {
    #navbarPanel {
      position: fixed;
      top: 0;
      height: 100vh;
      width: 100vw;
      left: 0;
      transition: none;

      .navbar-nav-overlay {
        background-color: rgba(255, 255, 255, 0.4);
        position: absolute;
        pointer-events: auto;
        cursor: pointer;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }

      .navbar-nav-panel {
        width: 300px;
        height: 100%;
        position: absolute;
        right: -300px;
        transition: all 0.4s;
        background-color: rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 30px 0px;
        display: flex;
        align-items: center;

        .navbar-nav {
          width: 100%;
          padding: 1.4rem;

          .nav-item {
            text-align: center;

            .nav-link {
              font-size: 1.4rem;
              color: map-get($theme-colors, 'primary');
              opacity: 0.8;
              font-weight: 700;

              &:after {
                background: map-get($theme-colors, 'primary');
              }

              &:hover {
                opacity: 1;
              }
            }
          }
        }

      }

      &.show {
        .navbar-nav-panel {
          right: 0;
        }
      }
    }
  }

  .hamburger {
    padding-top: 8px;
    padding-bottom: 0;
  }
}