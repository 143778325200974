h1, .h1 {
  font-weight: 800;
  font-size: 2.2rem;

  @include media-breakpoint-up(xl) {
    font-size: 2.8rem;
  }
}

h2, .h2 {
  font-weight: 800;
  font-size: 1.8rem;

  @include media-breakpoint-up(xl) {
    font-size: 2.4rem;
  }
}

h3, .h3 {
  font-weight: 800;
  font-size: 1.2rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.6rem;
  }
}

.font-weight-800 {
  font-weight: 800;
}