@import "theme";
@import "~bootstrap/scss/bootstrap";

@import "typography";
@import "nav";

@import "~hamburgers/_sass/hamburgers/hamburgers";

html, body {
  font-size: 16px;
  overflow-x: hidden;
  color: map-get($theme-colors, 'neutral');
}

header {
  .header {
    &__container {
      display: flex;
      justify-content: center;
      position: relative;
      height: auto;

      .row {
        max-width: 1200px;
      }

      h1 {
        margin-top: 32px;
      }

      .chart-container {
        margin: auto;
        max-width: 600px;
      }

      @include media-breakpoint-up(sm) {
        min-height: 750px;
        clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% calc(100% - 100px));
        overflow: hidden;
        padding-bottom: 5%;
        padding-top: 0;
      }

      @include media-breakpoint-up(xl) {
        height: calc(100vh + 200px);
        clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% calc(100% - 200px));
        min-height: 1050px;
        margin-top: -200px;
        padding-top: 200px;
        h1 {
          margin-top: 0;
        }
      }
    }

    &__background {
      width: 100%;
      height: 100%;
      background: rgb(65,204,147);
      background: linear-gradient(135deg, rgba(65,204,147,1) 0%, rgba(27,236,181,1) 100%);
      position: absolute;
    }
  }
}

.btn {
  min-width: 8rem;
  padding: 0.4rem 1.2rem;
  font-size: 1rem;

  @include media-breakpoint-up(lg) {
    min-width: 10rem;
    padding: 0.5rem 1.4rem;
    font-size: 1.4rem;
  }
  @include media-breakpoint-up(xl) {
    min-width: 12rem;
    padding: 0.5rem 1.8rem;
  }

  &-rounded {
    min-width: 48px;
    padding: 0.5rem 0;
    text-align: center;
    border-radius: 50%;

    @include media-breakpoint-down(md) {
      min-width: 32px;
      padding: 0.2rem 0;
      font-size: 1rem;
    }
  }

  &-sm {
    font-size: 1rem;
    min-width: 8rem;
    padding: 0.3rem 1rem;

    @include media-breakpoint-up(xl) {
      font-size: 1.2rem;
      min-width: 10rem;
      padding: 0.5rem 1.5rem;
    }
  }
}

.required {
  color: red;
}

.form-control {
  @include media-breakpoint-down(md) {
    font-size: 1rem !important;
  }
}

.carousel-indicators {
  li {
    background-color: map-get($theme-colors, 'neutral');
    &.active {
      background-color: map-get($theme-colors, 'primary');
    }
  }
}

.carousel-control-prev, .carousel-control-next {
  width: 40px;
}

.card {
  box-shadow: 0 1.6px 3.6px 0 rgba(0,0,0,0.132),0 0.3px 0.9px 0 rgba(0,0,0,0.108);

  &-container {
    border-bottom: 1px solid;
  }

  &-header {
    background-color: #fff;
    border-bottom: none;


    .btn {
      width: 100%;
      text-align: left;
      text-transform: uppercase;
      font-weight: 800;
      margin-bottom: 0;
      color: map-get($theme-colors, 'primary');
      padding-left: 0;
      padding-right: 0;
      font-size: 1.6rem;

      @include media-breakpoint-down(md) {
        font-size: 1.4rem;
      }

      &.collapsed {
        color: map-get($theme-colors, 'neutral');

        &:hover {
          color: map-get($theme-colors, 'primary');
        }
      }

      &:focus, &.focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

#meterChart {
  max-width: 100%;
}

.meter {
  position: relative;
  width: 280px;
  margin-bottom: 100px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
  }

  &__gauge {
    position: absolute;
    right: 70px;
    top: 34px;
    color: #fff;
    font-size: 1.4rem;
    letter-spacing: 0.25rem;
  }
}

#about {
  padding: 100px 0;

  @include media-breakpoint-up(md) {
    padding: 150px 0;
  }

  @include media-breakpoint-up(xl) {
    padding: 200px 0;
  }

  .about-brand {
    font-weight: 800;
    font-size: 2.2rem;
    line-height: 2.8rem;

    @include media-breakpoint-up(lg) {
      line-height: 3.6rem;
      font-size: 2.8rem;
    }
    @include media-breakpoint-up(xl) {
      line-height: 4.8rem;
      font-size: 3.8rem;
    }
  }
}

.percent-text {
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
}
.percent-sum {
  display: block;
  float: left;
  margin-right: 0.8rem;
  width: 3.2rem;
  min-width: 3.2rem;
  height: 3.2rem;
  line-height: 3.2rem;
  color: #fff;
  background-color: map-get($theme-colors, 'primary');
  border-radius: 3.2rem;
  text-align: center;
}

#benefits {
  &__row {
    & > div {
      background-color: map-get($theme-colors, 'neutralLight');
    }
  }

  .illustration {
    min-height: 200px;
    padding: 4rem;
    padding-bottom: 0;
    display: flex;
    align-items: center;

    img {
      height: 50%;
      max-width: 60%;

      @include media-breakpoint-down(lg) {
        max-width: 100%;
      }

      @include media-breakpoint-down(xs) {
        height: auto;
      }
    }
  }
}

#gallery {
  margin-top: 0;
  padding: 150px 0;

  @include media-breakpoint-down(xs) {
    padding: 100px 0;
  }

  .carousel-container {
    position: relative;

    .carousel {
      top: 4%;
      right: 19%;
      width: 62%;
      position: absolute;

      @include media-breakpoint-up(lg) {
        top: 4%;
        right: 2%;
        width: 75%;
      }
    }

    .carousel-frame {
      position: relative;
      max-width: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 200px 0;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 250px;
    padding-bottom: 200px;
  }
}

#enmon {
  padding: 0;

  @include media-breakpoint-up(lg) {
    padding: 100px 0;
  }

  .circle-wrap {
    text-align: center;

    .logo-icon {
      position: absolute;
      top: 173px;
      left: calc(50% - 31px);

      @include media-breakpoint-down(xs) {
        top: 90px;
        left: calc(50% - 18px);
        width: 36px;
      }
    }

    .circle {
      position: relative;
      display: inline-block;
      padding: 40px;
      width: 256px;
      height: 256px;
      border-radius: 256px;
      opacity: 0.8;
      color: map-get($theme-colors, 'neutral');

      @include media-breakpoint-down(xs) {
        width: 128px;
        height: 128px;
        border-radius: 128px;
      }

      p {
        position: absolute;
        min-width: 210px;
        @include media-breakpoint-down(xs) {
          min-width: 0;
          font-size: 0.8rem;
        }
      }

      &:nth-child(1) {
        display: block;
        margin: 0 auto -60px;
        background-color: #5916d2;
        p {
          top: -65px;
          left: -25px;
        }
        @include media-breakpoint-down(xs) {
          margin: 0 auto -50px;
          p {
            top: -78px;
            left: 0;
          }
        }
      }

      &:nth-child(2) {
        margin-top: -60px;
        margin-right: -60px;
        background-color: #3315d1;
        p {
          text-align: left;
          top: 256px;
          right: 100px;
        }
        @include media-breakpoint-down(xs) {
          margin-top: 0;
          margin-right: -20px;

          p {
            top: 120px;
            right: 0;
          }
        }
      }

      &:nth-child(3) {
        margin-top: -60px;
        margin-left: -60px;
        background-color: #7e5bdf;
        p {
          text-align: left;
          top: 256px;
          left: 100px;
        }
        @include media-breakpoint-down(xs) {
          margin-top: 0;
          margin-left: -20px;

          p {
            top: 120px;
            left: 40px;
          }
        }
      }
    }
  }
}

#how-it-works {
  position: relative;
  min-height: 400px;
  display: flex;
  align-items: center;

  .btn {
    position: absolute;
    //filter: drop-shadow(0 1.6px 3.6px rgba(0,0,0,0.4));
    font-weight: 700;
    opacity: 0.6;
    transition: all 0.4s;

    &:hover {
      background-color: #37c48a;
      opacity: 1;
    }
  }

  & .container {
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }

    button:nth-child(2) {
      left: 15%;
      top: 83%;
    }
    button:nth-child(3) {
      left: 2%;
      top: 55%;
    }
    button:nth-child(4) {
      left: 19%;
      top: 29%;
    }
    button:nth-child(5) {
      left: 32%;
      top: 6%;
    }
    button:nth-child(6) {
      left: 42%;
      bottom: 38%;
    }
    button:nth-child(7) {
      top: 46%;
      right: 35%;
    }
    button:nth-child(8) {
      top: 41%;
      right: 3%;
    }

  }



  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: -200px;
    bottom: -200px;
    width: 100%;
    background: rgb(110,19,125);
    background: linear-gradient(135deg, rgba(110,19,125,1) 0%, rgba(38,83,160,1) 100%);
  }
}

#solution {
  margin-top: 0;
  padding: 150px 0;

  @include media-breakpoint-down(xs) {
    padding: 100px 0;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 200px;
    padding-bottom: 250px;
  }

  #solutio-accordion {
    .card-body {
      @include media-breakpoint-down(md) {
        padding-top: 0;
      }
      img {
        filter: grayscale(1);
        transition: filter 0.4s;

        &:hover {
          filter: none;
        }
      }
    }
  }
}

#references {
  @include media-breakpoint-down(md) {
    padding: 50px 0;
  }

  .reference {
    background-color: #f0f0f0;
    border: none;

    &-photo {
      position: relative;

      &:after {
        content: ' ';
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #103b29;
        z-index: 1;
        opacity: 0.4;
      }

      .photo {
        width: 100%;
      }

      .logo {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        z-index: 2;
        @include media-breakpoint-down(md) {
          max-width: 75px;
        }
      }
    }
  }
}

#contact {
  padding: 100px 0 150px;
  margin-top: 0;

  @include media-breakpoint-up(lg) {
    padding: 150px 0;
  }

  @include media-breakpoint-up(xl) {
    padding: 250px 0;
  }
}

.shadow-container {
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3));
}

.diagonal-section {
  display: flex;
  align-items: stretch;
  position: relative;
  background-color: #fff;
  clip-path: polygon(0% 0%, 100% 50px, 100% 100%, 0% calc(100% - 50px));
  margin-top: -50px;

  @include media-breakpoint-up(sm) {
    clip-path: polygon(0% 0%, 100% 100px, 100% 100%, 0% calc(100% - 100px));
    min-height: 500px;
    margin-top: -100px;
  }

  @include media-breakpoint-up(xl) {
    clip-path: polygon(0% 0%, 100% 200px, 100% 100%, 0% calc(100% - 200px));
    min-height: 700px;
    margin-top: -200px;
  }

  &--alt {
    position: relative;
    clip-path: polygon(0% 50px, 100% 0%, 100% calc(100% - 50px), 0% 100%);

    @include media-breakpoint-up(sm) {
      min-height: 600px;
      clip-path: polygon(0% 100px, 100% 0%, 100% calc(100% - 100px), 0% 100%);
    }

    @include media-breakpoint-up(xl) {
      clip-path: polygon(0% 200px, 100% 0%, 100% calc(100% - 200px), 0% 100%);
      min-height: 700px;
    }
  }

  &--dark {
    background-color: map-get($theme-colors, 'neutralLight');

    @include media-breakpoint-up(md) {
      h2 {
        margin-top: 5rem;
      }
    }
  }

  &__text {
    max-width: 680px;

    @include media-breakpoint-down(sm) {
      margin-top: 75px;
      margin-bottom: 75px;
    }

    @include media-breakpoint-down(xs) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#contact {
  z-index: 2;
  background: linear-gradient(45deg, #2653a0, #6e137d, #37c48a, #FF7F48);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.contact {
  padding-bottom: 300px;

  #particles-js {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  a {
    color: #fff;
  }
}

.opacity-loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  opacity: 0.6;
}

footer {
  #map {
    height: 500px;
    width: 100%;
    z-index: 1;
    position: relative;
  }
  margin-top: -300px;
}

.popover {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(234, 234, 234);
  outline: transparent none medium;
  border-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px 0px
}

.popover-header {
  font-weight: 400;
  text-align: center;
  border-radius: 0;
  font-size: 1rem;
  color: #fff;
  background-color: rgb(47, 47, 47);
}

.popover-body {
  border-radius: 0;
}